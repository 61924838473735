import { getParentBySelector } from "../helper"

export class SelectionLimiter {
  constructor(wrapperClass, limit = 1, itemClass = ".gift-maker__product") {
    let inputs = document.querySelectorAll(`.gift-maker ${wrapperClass} input`)

    this.wrapperClass = wrapperClass
    this.limit = limit
    this.itemClass = itemClass

    inputs.forEach((input) => {
      input.addEventListener("change", this.changeHandler.bind(this), true)
    })
  }


  changeHandler(event) {
    this.clickHandler(this.wrapperClass, this.limit, this.itemClass)
  }

  clickHandler(wrapperClass, limit, itemClass) {
    let checked, unchecked

    checked = document.querySelector(wrapperClass).querySelectorAll("input[type=checkbox]:checked")

    if (checked.length > limit) {

      unchecked = document.querySelector(wrapperClass).querySelectorAll("input[type=checkbox]:not(input[type=checkbox]:checked)")

      unchecked.forEach((input) => {
        getParentBySelector(input, itemClass).classList.add("disabledLink")
      })
    } else if (checked.length == limit) {
      unchecked = document.querySelector(wrapperClass).querySelectorAll("input[type=checkbox]:not(input[type=checkbox]:checked)")

      unchecked.forEach((input) => {
        getParentBySelector(input, itemClass).classList.add("disabledLink")
      })
    } else {
      unchecked = document.querySelector(wrapperClass).querySelectorAll("input[type=checkbox]:not(input[type=checkbox]:checked)")

      unchecked.forEach((input) => {
        getParentBySelector(input, itemClass).classList.remove("disabledLink")
      })
    }
  }
}
