import Swiper, { Autoplay, Navigation, Pagination } from "swiper"

Swiper.use([Autoplay, Navigation, Pagination])

window.activeGiftMakerSwiper = {}

export function initHomepageSlider() {
  const sliderSection = document.querySelector(".homepage__slider")

  if (sliderSection) {
    new Swiper(sliderSection.querySelector(".swiper"), {
      slidesPerView: 1,
      watchOverflow: true,
      speed: 500,
      autoplay: {
        delay: 3000
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
    })
  }
}

export function initShopByCategorySlider() {
  const sliderSection = document.querySelector(".homepage__shop-by-category")

  if (sliderSection) {
    new Swiper(sliderSection.querySelector(".swiper-container"), {
      slidesPerView: 1.3,
      slidesPerGroup: 1,
      speed: 500,
      spaceBetween: 5,
      breakpoints: {
        1200: {
          slidesPerView: 4,
        },
        576: {
          slidesPerView: 3,
        }
      },
      watchOverflow: true,
      pagination: {
        el: '.homepage__shop-by-category .homepage-pagination',
        type: 'fraction'
      },
      navigation: {
        nextEl: sliderSection.querySelector('.btn-pagination--right'),
        prevEl: sliderSection.querySelector('.btn-pagination--left')
      }
    })
  }
}

export function initSpecialGiftsSlider() {
  const sliderSection = document.querySelector(".homepage__special-gifts")

  if (sliderSection) {
    new Swiper(sliderSection.querySelector(".swiper-container"), {
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 10,
      watchOverflow: true,
      breakpoints: {
        576: {
          slidesPerView: 2,
          spaceBetween: 30
        }
      },
      pagination: {
        el: '.homepage__special-gifts .homepage-pagination',
        type: 'fraction'
      },
      navigation: {
        nextEl: sliderSection.querySelector('.btn-pagination--right'),
        prevEl: sliderSection.querySelector('.btn-pagination--left')
      }
    })
  }
}

export function initHomepageBundlesTabSlider() {
  function initTabSlider() {
    setTimeout(() => {
      const sliderSection = document.querySelector(".homepage__promotions")

      if (sliderSection) {
        let initial_tab = sliderSection.querySelector(".tab-pane.active")

        initSlider(initial_tab)

        sliderSection.querySelectorAll("button[data-bs-toggle='tab']").forEach(tab => {
          tab.addEventListener("shown.bs.tab", function () {
            let getId = tab.getAttribute("data-bs-target")
            let active_tab = sliderSection.querySelector(`.tab-pane${getId}`)

            initSlider(active_tab)
          }, { once: true })
        })
      }
    }, 300)
  }

  function initSlider(active_tab) {
    setTimeout(() => {
      const sliderSection = active_tab.querySelector(".swiper-container")

      if (sliderSection) {
        new Swiper(sliderSection, {
          slidesPerView: 1.2,
          slidesPerGroup: 1,
          spaceBetween: 10,
          watchOverflow: true,
          pagination: {
            el: active_tab.querySelector('.homepage__promotions .homepage-pagination'),
            type: 'fraction'
          },
          navigation: {
            nextEl: active_tab.querySelector('.btn-pagination--right'),
            prevEl: active_tab.querySelector('.btn-pagination--left')
          },
          breakpoints: {
            1400: {
              slidesPerView: 3,
            },
            576: {
              slidesPerView: 2,
              spaceBetween: 30,
            }
          }
        })
      }
    })
  }

  initTabSlider()
}

export function initShopNewInTabSlider() {
  function initShopNewInSlider() {
    setTimeout(() => {
      const sliderSection = document.querySelector(".homepage__shop-new-in")

      if (sliderSection) {
        let initial_tab = sliderSection.querySelector(".tab-pane.active")

        initHomepagePresents(initial_tab)

        sliderSection.querySelectorAll("button[data-bs-toggle='tab']").forEach(tab => {
          tab.addEventListener("shown.bs.tab", function () {
            let getId = tab.getAttribute("data-bs-target")
            let active_tab = sliderSection.querySelector(`.tab-pane${getId}`)

            initHomepagePresents(active_tab)
          }, { once: true })
        })
      }
    }, 300)
  }

  function initHomepagePresents(active_tab) {
    setTimeout(() => {
      const sliderSection = active_tab.querySelector(".swiper-container")

      if (sliderSection) {
        new Swiper(sliderSection, {
          slidesPerView: 1,
          slidesPerGroup: 1,
          spaceBetween: 15,
          watchOverflow: true,
          breakpoints: {
            1200: {
              slidesPerView: 4
            },
            576: {
              slidesPerView: 3,
              spaceBetween: 30
            }
          },
          pagination: {
            el: active_tab.querySelector(".swiper-pagination"),
            type: "bullets",
            clickable: true
          }
        })
      }
    })
  }

  initShopNewInSlider()
}

export function initBestSellersSlider() {
  const sliderSection = document.querySelector(".homepage__best-sellers")

  if (sliderSection) {
    new Swiper(sliderSection.querySelector(".swiper-container"), {
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 15,
      watchOverflow: true,
      breakpoints: {
        1200: {
          slidesPerView: 4,
        },
        576: {
          slidesPerView: 3,
          spaceBetween: 30
        }
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      }
    })
  }
}

export function initChristmasSpiritTabSlider() {
  function initChristmasSpiritSlider() {
    setTimeout(() => {
      const sliderSection = document.querySelector(".homepage__christmas-spirit")

      if (sliderSection) {
        let initial_tab = sliderSection.querySelector(".tab-pane.active")
        initHomepagePresents(initial_tab)

        sliderSection.querySelectorAll("button[data-bs-toggle='tab']").forEach(tab => {
          tab.addEventListener("shown.bs.tab", function () {
            let getId = tab.getAttribute("data-bs-target")
            let active_tab = sliderSection.querySelector(`.tab-pane${getId}`)
            initHomepagePresents(active_tab)
          }, { once: true })
        })
      }
    }, 300)
  }

  function initHomepagePresents(active_tab) {
    setTimeout(() => {
      const sliderSection = active_tab.querySelector(".swiper-container")

      if (sliderSection) {
        new Swiper(sliderSection, {
          slidesPerView: 1,
          slidesPerGroup: 1,
          speed: 500,
          watchOverflow: true,
          pagination: {
            el: active_tab.querySelector(".swiper-pagination"),
            type: 'bullets',
            clickable: true
          },
          breakpoints: {
            1200: {
              slidesPerView: 3,
            },
            576: {
              slidesPerView: 2,
              spaceBetween: 15,
            }
          },
        })
      }
    })
  }

  initChristmasSpiritSlider()
}

export function initSpecialCollectionsSlider() {
  const sliderSection = document.querySelector(".homepage__special-collections")

  if (sliderSection) {
    new Swiper(sliderSection.querySelector(".swiper-container"), {
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 10,
      watchOverflow: true,
      breakpoints: {
        1200: {
          slidesPerView: 3,
        },
        576: {
          slidesPerView: 2,
        }
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      }
    })
  }
}

export function initHomepageBlogSlider() {
  const sliderSection = document.querySelector(".homepage__articles")

  if (sliderSection) {
    new Swiper(sliderSection.querySelector(".swiper-container"), {
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 10,
      watchOverflow: true,
      breakpoints: {
        1400: {
          slidesPerView: 3,
        },
        576: {
          slidesPerView: 2,
        }
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      }
    })
  }
}

export function initMainProductSlider() {
  setTimeout(() => {
    const slider = document.querySelector(".product-page__main-photo")

    if (slider.querySelectorAll(".swiper-slide").length > 1) {
      new Swiper(slider, {
        slidesPerView: 1,
        slidesPerGroup: 1,
        watchOverflow: true,
        speed: 500,
        preloadImages: false,
        pagination: {
          el: '.product-page__main-photo .swiper-pagination',
          type: 'bullets',
          clickable: true
        }
      })
    }
  }, 1000)
}

export function initRelatedProductsSlider() {
  const sliderSection = document.querySelector(".product-page__related")

  if (sliderSection) {
    const swiperContainer = sliderSection.querySelector(".swiper-container")

    if (swiperContainer.classList.contains("swiper-container-initialized")) return

    new Swiper(swiperContainer, {
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 10,
      watchOverflow: true,
      breakpoints: {
        1200: {
          slidesPerView: 4,
        },
        576: {
          slidesPerView: 3,
        }
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      }
    })
  }
}

export function initGiftMakerStep2Slider() {
  window.initGiftMakerStep2Slider = function () {
    const sliderSection = document.querySelector(".gift-maker__step-2")

    if (sliderSection) {
      new Swiper(sliderSection.querySelector(".swiper-container"), {
        slidesPerView: 1,
        spaceBetween: 10,
        watchOverflow: true,
        breakpoints: {
          1200: {
            slidesPerView: 8,
            spaceBetween: 15,
          },
          576: {
            slidesPerView: 4
          }
        },
        pagination: {
          el: '.gift-maker__step-2 .gift-maker-pagination',
          type: 'fraction'
        },
        navigation: {
          nextEl: sliderSection.querySelector('.btn-pagination--right'),
          prevEl: sliderSection.querySelector('.btn-pagination--left')
        }
      })
    }
  }

  window.initGiftMakerStep2Slider()
}

export function initGiftMakerStep3Slider() {
  function initGiftMakerStep3TabSlider() {
    setTimeout(() => {
      const sliderSection = document.querySelector(".gift-maker__step-3")

      if (sliderSection) {

        let initial_tab = sliderSection.querySelector(".tab-pane.active")
        initGiftMakersStep3(initial_tab)
        sliderSection.querySelectorAll("button[data-bs-toggle='tab']").forEach(tab => {

          tab.addEventListener("shown.bs.tab", function () {
            let getId = tab.getAttribute("data-bs-target")
            let active_tab = sliderSection.querySelector(`.tab-pane${getId}`)
            initGiftMakersStep3(active_tab)
          })
        })
      }
    }, 300)
  }

  function initGiftMakersStep3(active_tab) {
    setTimeout(() => {
      const sliderSection = active_tab.querySelector(".swiper-container")
      var giftMakerSwiper

      if (sliderSection) {
        giftMakerSwiper = new Swiper(sliderSection, {
          slidesPerView: 1,
          slidesPerGroup: 1,
          speed: 500,
          watchOverflow: true,
          observer: true,
          observeParents: true,
          observeSlideChildren: true,
          breakpoints: {
            1200: {
              slidesPerView: 4,
            },
            576: {
              slidesPerView: 3,
              spaceBetween: 15,
            },
            330: {
              slidesPerView: 1.3,
            }
          },
          pagination: {
            el: active_tab.querySelector(".gift-maker__step-3 .gift-maker-pagination"),
            type: "fraction"
          },
          navigation: {
            nextEl: active_tab.querySelector(".btn-pagination--right"),
            prevEl: active_tab.querySelector(".btn-pagination--left")
          }
        })
      }

      window.activeGiftMakerSwiper = giftMakerSwiper
    })
  }

  initGiftMakerStep3TabSlider()
}

export function initGiftMakerStep4Slider() {
  const sliderSection = document.querySelector(".gift-maker__step-4")

  if (sliderSection) {
    new Swiper(sliderSection.querySelector(".swiper-container"), {
      slidesPerView: 1,
      slidesPerGroup: 1,
      speed: 500,
      watchOverflow: true,
      breakpoints: {
        1200: {
          slidesPerView: 4,
        },
        576: {
          slidesPerView: 3,
          spaceBetween: 15,
        },
        330: {
          slidesPerView: 1.3,
        }
      },
      pagination: {
        el: '.gift-maker__step-4 .gift-maker-pagination',
        type: 'fraction'
      },
      navigation: {
        nextEl: sliderSection.querySelector('.btn-pagination--right'),
        prevEl: sliderSection.querySelector('.btn-pagination--left')
      }
    })
  }
}

export function initGiftMakerStep5Slider() {
  const sliderSection = document.querySelector(".gift-maker__step-5")

  if (sliderSection) {
    new Swiper(sliderSection.querySelector(".swiper-container"), {
      slidesPerView: 1,
      slidesPerGroup: 1,
      speed: 500,
      watchOverflow: true,
      breakpoints: {
        1200: {
          slidesPerView: 4,
        },
        576: {
          slidesPerView: 3,
          spaceBetween: 15,
        },
        330: {
          slidesPerView: 1.3,
        }
      },
      pagination: {
        el: '.gift-maker__step-5 .gift-maker-pagination',
        type: 'fraction'
      },
      navigation: {
        nextEl: sliderSection.querySelector('.btn-pagination--right'),
        prevEl: sliderSection.querySelector('.btn-pagination--left')
      }
    })
  }
}

export function initArticlePageRelatedSlider() {
  const sliderSection = document.querySelector(".article-page__related-products")

  if (sliderSection) {
    new Swiper(sliderSection.querySelector(".swiper-container"), {
      slidesPerView: 1.5,
      slidesPerGroup: 1,
      spaceBetween: 10,
      watchOverflow: true,
      breakpoints: {
        1200: {
          slidesPerView: 4,
        },
        576: {
          slidesPerView: 3,
        }
      }
    })
  }
}