export class HeightEqualizer {
  constructor(selector = ".equal-height") {
    const elements = document.querySelectorAll(selector)

    for (let el of elements) {
      this.equalizeHeight(el)
    }
  }

  equalizeHeight(el) {
    const getDivs = Array.from(el.getElementsByClassName("eq")).filter(element => element.offsetWidth > 0 && element.offsetHeight > 0)

    let heights = []

    for (let div of getDivs) {
      heights.push(div.offsetHeight)
    }

    let tallest = Math.max(...heights)

    for (let div of getDivs) {
      div.style.height = `${tallest}px`
    }
  }
}