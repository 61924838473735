import { SelectionLimiter } from "./SelectionLimiter"
import { getParentBySelector } from "../helper"

export class GiftMakerSelectionLimiter {
  constructor(refresh = false) {
    let giftMakerQuantities = document.querySelector(".gift-maker-quantities")
    let selectedQuantityWrapper
    let selectedQuantity = "Έως 8 φιάλες"

    if (selectedQuantityWrapper = giftMakerQuantities.querySelector(".active")) {
      selectedQuantity = selectedQuantityWrapper.innerHTML
    }

    if (refresh) {
      let inputs, product

      inputs = document.querySelector(".gift-maker__step-3").querySelectorAll("input[type=checkbox]")
      inputs.forEach((input) => {
        input.checked = false

        product = getParentBySelector(input, ".gift-maker__product")
        product.classList.remove("disabledLink")
        product.classList.remove("active-product")
      })
    } else {
      new SelectionLimiter(".gift-maker__step-4")
      new SelectionLimiter(".gift-maker__step-5")
    }

    new SelectionLimiter(".gift-maker__step-2")
    new SelectionLimiter(".gift-maker__step-3", this._getQuantityMappings(selectedQuantity))
  }

  _getQuantityMappings(key) {
    return {
      "Έως 1 φιάλη": 1,
      "Έως 2 Φιάλες": 2,
      "Έως 3 φιάλες": 3,
      "Έως 4 φιάλες": 4,
      "Έως 5 φιάλες": 5,
      "Έως 6 φιάλες": 6,
      "Έως 8 φιάλες": 8,
      "1 φιάλη magnum (1,5 L)": 1,
      "έως 1 φιάλη μικρή (375ml ή 350ml)": 1
    }[key]
  }
}
