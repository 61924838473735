// Adds the functionality for the responsive menu. Open, close, nextlevel and backlevel
export function responsiveMenu() {
  const mainMenu = document.querySelector(".main-menu")
  const menuIcon = document.querySelector(".menu-trigger")
  const toggleMenu = document.querySelector(".menu-trigger em")
  const menuItemHasChildren = document.querySelectorAll(".menu-item-has-children")
  const closeMobile = document.querySelector(".close-mobile")

  menuIcon.addEventListener("click", (e) => {
    e.preventDefault()

    if (document.querySelector(".menu-trigger .icon-menu")) {
      mainMenu.classList.remove("d-none")
      mainMenu.classList.add("d-block")
      toggleMenu.classList.remove("icon-menu")
      toggleMenu.classList.add("icon-close")
    }
    else if (document.querySelector(".menu-trigger .icon-close")) {
      mainMenu.classList.remove("d-block")
      mainMenu.classList.add("d-none")
      toggleMenu.classList.remove("icon-close")
      toggleMenu.classList.add("icon-menu")
    }
  })

  if (window.innerWidth < 1200) {

    menuIcon.addEventListener("click", (e) => {
      e.preventDefault()

      mainMenu.classList.remove("main-menu--desktop")
      mainMenu.classList.add("main-menu--mobile")
    })

    menuItemHasChildren.forEach(level => {

      level.querySelector("a").addEventListener("click", function (e) {
        e.preventDefault()

        this.classList.toggle("active")
        level.querySelector(".main-menu__l2").classList.toggle("active")
      })
    })

    closeMobile.addEventListener("click", function () {
      mainMenu.classList.remove("d-block")
      toggleMenu.classList.remove("icon-close")
      toggleMenu.classList.add("icon-menu")
    })
  }
}

// Change Menu Background on first level hover
export function updateMenuBackground() {
  const desktopMenu = document.querySelector('.main-menu--desktop')

  if (desktopMenu) {
    const menuList2 = [...document.querySelectorAll('.main-menu__l2')]

    desktopMenu.addEventListener('mouseover', function () {
      hoverOnList(menuList2, 'main-menu__l2', 'main-menu--desktop__background')
    })

    const hoverOnList = (menuList, menuListItem, mainMenu) => {
      for (let list of menuList) {
        if (window.getComputedStyle(list).display === 'flex' && list.classList.contains(menuListItem)) {
          desktopMenu.classList.add(mainMenu)
          break

        } else if (window.getComputedStyle(list).display === 'none') {
          desktopMenu.classList.remove(mainMenu)
        }
      }
    }
  }
}