/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//

// jQuery is added strictly for the Analytics (gya.js)
import $ from "jquery"
window.$ = window.jQuery = $

import "bootstrap/js/dist/modal"
import "bootstrap/js/dist/dropdown"

import { bindGYEvents } from "../gy-events"
import { startRails, freezeBackground, unfreezeBackground } from "../shared"
import { responsiveMenu, updateMenuBackground } from "../header/mainMenu"
import { initSearch } from "../header/search"
import { initMiniCartPanel } from "../pages/miniCart"
import { loadWebFonts } from "../typography/fonts"

const GY = {
  loadShared: function () {
    loadWebFonts()
    startRails()
    bindGYEvents()
    responsiveMenu()
    updateMenuBackground()
    freezeBackground()
    unfreezeBackground()
    initSearch()
    initMiniCartPanel()
  },
  loadHomepage: function () {
    import("../initializers/homepageInitializer")
      .then((module) => { module.homepageInitializer() })
  },
  loadCatalog: function () {
    import("../initializers/catalogInitializer")
      .then((module) => { module.catalogInitializer() })
  },
  loadExpertsPage: function () {
    import("../initializers/expertsPageInitializer")
      .then((module) => { module.expertsPageInitializer() })
  },
  loadStaticPages: function () {
    import("../initializers/staticPagesInitializer")
      .then((module) => { module.staticPagesInitializer() })
  },
  loadOrder: function () {
    import("../initializers/orderInitializer")
      .then((module) => { module.staticPagesInitializer() })
  },
  loadCheckout: function () {
    import("../initializers/checkoutInitializer")
      .then((module) => { module.checkoutInitializer() })
  },
  loadGiftMaker: function () {
    import("../initializers/giftMakerInitializer")
      .then((module) => { module.giftMakerInitializer() })
  },
  loadArticlePage: function () {
    import("../initializers/articlePageInitializer")
      .then((module) => { module.articlePageInitializer() })
  }
}

GY.loadShared()

if (document.querySelector(".homepage")) {
  import("bootstrap/js/dist/tab")

  GY.loadHomepage()
}
else if (document.querySelector(".catalog-page")) {
  GY.loadCatalog()
}
else if (document.querySelector(".product-page")) {
  import("bootstrap/js/dist/tab")
}
else if (document.querySelector(".static-page")) {
  GY.loadStaticPages()
}
else if (document.querySelector(".checkout-2")) {
  GY.loadCheckout()
}
else if (document.querySelector(".wishlist-page")) {

}
else if (document.querySelector(".gift-maker")) {
  import("bootstrap/js/dist/tab")

  GY.loadGiftMaker()
}
else if (document.querySelector(".article-page")) {
  GY.loadArticlePage()
}
else if (document.querySelector(".experts-page")) {
  import("bootstrap/js/dist/collapse")

  GY.loadExpertsPage()
}
