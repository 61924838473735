// Opens and closes the minicart
export function initMiniCartPanel() {
  document.querySelector("a[data-panel='minicart']").addEventListener("click", (e) => {
    e.preventDefault()

    document.querySelector(".minicart-panel").classList.add("minicart-panel--active")
    document.querySelectorAll("body", "html").forEach(el => el.setAttribute("overflow", "hidden"))
  })

  document.querySelector(".minicart-panel__close").addEventListener("click", (e) => {
    e.preventDefault()

    document.querySelector(".minicart-panel").classList.remove("minicart-panel--active")
    document.querySelectorAll("body", "html").forEach(el => el.setAttribute("overflow", "auto"))
  })
}
